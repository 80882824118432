<template>
    <div class="info-site-wrapper kath-sz-card pt-3 pb-3">
        <div class="info-site-title sz-kath-title"
             v-html="infoSite.title"
        >
        </div>
        <div class="info-site-sub-title"
             v-if="infoSite.hasOwnProperty('subtitle')"
             v-html="infoSite.subtitle"
        >

        </div>
        <div class="termin"
             v-if="infoSite.hasOwnProperty('termin')"
             v-html="infoSite.termin"
        >

        </div>
        <div class="info-site-text"
             v-if="infoSite.hasOwnProperty('value')"
             v-html="infoSite.value"
        >

        </div>
        <div class="info-site-data-tables one-column-wrapper"
             v-if="infoSite.hasOwnProperty('tables')">
            <vote-table v-for="table in infoSite.tables"
                        :key="table.title"
                        :table="table"
            ></vote-table>
        </div>
        <div class="info-site-vote-map"
             v-if="infoSite.hasOwnProperty('voteMap')"
             >
            <v-img :src="infoSite.voteMap.src"
                   :alt="infoSite.voteMap.alt"></v-img>
        </div>
        <div v-if="infoSite.hasOwnProperty('files')" class="info-site-files">
            <div class="font-weight-bold"
                 v-html="infoSite.files.filesTitle"
            >
            </div>
            <div v-for="file in infoSite.files.filesList"
                 :key="file.name"
                 @click="openFile(file.path)"
                 v-html="file.name"
                 class="link mt-1 mb-1"
            >

            </div>
            <div v-if="infoSite.hasOwnProperty('links')"
                 v-for="link in infoSite.links"
                 :key="link.path"
                 @click="navPage(link.page, link.id)"
                 class="link"
            >
                {{link.name}}
            </div>
        </div>
    </div>
</template>

<script>
    import VoteTable from "../components/infoSite/VoteTable";
    import router from "../router"
    export default {
        name: "InfoSite",
        props: ['id'],
        components: {
            VoteTable
        },
        computed: {
            infoSite () {
                return this.$store.getters.infoSite(this.id)
            }
        },
        methods: {
            navPage(page, id) {
                if (id===undefined) {
                    router.push({path: page})
                } else {
                    router.push({name: page, params: {id: id}});
                }
            },
            openFile(filename){
                window.open( filename, '_blank');
            },
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToSmall');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
    }
</script>

<style scoped>

</style>